<template>
  <div>
    <div class="adv" v-if="$originCopyWriting == 'qagame' || $originCopyWriting == 'getfun' || adv_style" style="display: flex;justify-content: center;align-items: center;width: 314px;margin: 0 auto" @mouseenter="advMouseenter">
      <div v-if="mNoAfc" :id="gptId" ref="advRef"></div>
      <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
           :style="adv_style ? {display:'block',width: '300px',height: '250px'} : {display:'block',width: '100%'}"
           :data-ad-client="caPub"
           :data-ad-slot="slot"
           data-ad-format="true"
           data-full-width-responsive="true"></ins>
    </div>
    <div v-if="$originCopyWriting != 'qagame' && $originCopyWriting != 'getfun' && !adv_style">
      <MobileLogo whereFrom="1" portal="mobile_home"></MobileLogo>
    </div>
    <div v-show="clientWidth <= 550.9">
      <div class="app_list app_list_1" v-for="(item,index) in appGameList" :key="index">
        <div :class="index == 0 ? ($originCopyWriting == 'qagame' || $originCopyWriting == 'getfun' || adv_style ? 'adv_box_6': adv_box_2) : ((slot2 || gptId2) && index == 1) ? adv_box_2 : adv_box">
          <div v-if="($originCopyWriting == 'qagame' || $originCopyWriting == 'getfun' || adv_style) && index == 0" style="grid-area: logo">
            <MobileLogo whereFrom="1" portal="mobile_home" :qagameStyle="true"></MobileLogo>
          </div>
          <div class="adv" v-if="($originCopyWriting != 'qagame' && $originCopyWriting != 'getfun' && !adv_style) && index == 0 && clientWidth <= 550.9" style="grid-area: adv;display: flex;justify-content: center;align-items: center" @mouseenter="advMouseenter">
            <div v-if="mNoAfc" :id="gptId" ref="advRef"></div>
            <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
                 style="display:block;width: 100%"
                 :data-ad-client="caPub"
                 :data-ad-slot="slot"
                 data-ad-format="true"
                 data-full-width-responsive="true"></ins>
          </div>
          <div class="adv" v-if="(slot2 || gptId2) && index == 1 && clientWidth <= 550.9" style="grid-area: adv;" @mouseenter="advMouseenter">
            <div v-if="mNoAfc" :id="gptId2" ref="advRef2"></div>
            <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
                 style="display:block;width: 100%"
                 :data-ad-client="caPub"
                 :data-ad-slot="slot2"
                 data-ad-format="true"
                 data-full-width-responsive="true"></ins>
          </div>
          <a
              v-for="(items,indexs) in item"
              :key="indexs"
              :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+items.Name.replace(/\s+/g, '')+'?gameId='+items.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+items.Name.replace(/\s+/g, '')+'?gameId='+items.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"
              class="sc-wr3rvk-0 a_style_1 a_style_2"
              :style="indexs == 0 || indexs == 6 ? {gridArea: 'bigIp' + indexs}: null"
              @click="iconClick(items)"
          >
            <img v-if="indexs == 0 || indexs == 6" v-lazy="items.iconUrl" alt="" width="204px" height="204px" class="img_style">
            <img v-else v-lazy="items.iconUrl" alt="" width="94px" height="94px" class="img_style">
            <span class="opacity position">{{items.Name}}</span>
            <div class="hot_box" v-if="items.hotType">
              <div class="hot_img">
                <img :src="huore" alt="">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-show="clientWidth > 550.9" class="big_box">
      <div :class=" ($originCopyWriting == 'qagame' || $originCopyWriting == 'getfun' || adv_style ? (slot2 || gptId ? 'adv_box_8' : 'adv_box_7') : slot2 || gptId ? adv_box_3 : adv_box_4)">
        <div v-if="$originCopyWriting == 'qagame' || $originCopyWriting == 'getfun' || adv_style" style="grid-area: logo">
          <MobileLogo whereFrom="1" portal="mobile_home" :qagameStyle="true"></MobileLogo>
        </div>
        <div class="adv" v-if="($originCopyWriting != 'qagame' && $originCopyWriting != 'getfun' && !adv_style) && clientWidth > 550.9" style="grid-area: adv;min-height: 314px;display: flex;justify-content: center;align-items: center" @mouseenter="advMouseenter">
          <div v-if="mNoAfc" :id="gptId" ref="advRef"></div>
          <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
               style="display:block;width: 100%"
               :data-ad-client="caPub"
               :data-ad-slot="slot"
               data-ad-format="true"
               data-full-width-responsive="true"></ins>
        </div>
        <div class="adv" v-if="clientWidth > 550.9 && (slot2 || gptId2)" style="grid-area: adv2;min-height: 314px" @mouseenter="advMouseenter">
          <div v-if="mNoAfc" :id="gptId2" ref="advRef2"></div>
          <ins v-if="!noAdvertisements && !mNoAfc" class="adsbygoogle"
               style="display:block;width: 100%"
               :data-ad-client="caPub"
               :data-ad-slot="slot2"
               data-ad-format="true"
               data-full-width-responsive="true"></ins>
        </div>
        <div style="display: contents">
          <ul class="ul_style">
            <li v-for="(item,index) in bigImg" :key="index">
              <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" class="sc-wr3rvk-0 a_style_1 a_style_2" :style="{gridArea: 'bigIp' + index}" @click="iconClick(item)">
                <img v-lazy="item.iconUrl" alt="" width="314px" height="314px" class="img_style">
                <span class="opacity position">{{item.Name}}</span>
                <div class="hot_box" v-if="item.hotType">
                  <div class="hot_img">
                    <img :src="huore" alt="">
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div style="display: contents">
          <a v-for="(item,index) in centreImg" :key="index" :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" class="sc-wr3rvk-0 a_style_1 a_style_2" :style="{gridArea: 'ip' + (index + 3)}" @click="iconClick(item)">
            <img v-lazy="item.iconUrl" alt="" width="204px" height="204px" class="img_style">
            <span class="opacity position">{{item.Name}}</span>
            <div class="hot_box" v-if="item.hotType">
              <div class="hot_img">
                <img :src="huore" alt="">
              </div>
            </div>
          </a>
        </div>
        <div style="display: contents">
          <a v-for="(item,index) in smallImg" :key="index" :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))" class="sc-wr3rvk-0 a_style_1 a_style_2" @click="iconClick(item)">
            <img v-lazy="item.iconUrl" alt="" width="94px" height="94px" class="img_style">
            <span class="opacity position">{{item.Name}}</span>
            <div class="hot_box" v-if="item.hotType">
              <div class="hot_img">
                <img :src="huore" alt="">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="bottom">
      <BottomList whereFrom="1"></BottomList>
      <div class="bottom-text">
        <div class="about">About  {{$headToUpperCase && $headToUpperCase}}</div>
        <div class="title"><h1>Free Online Games</h1></div>
        <p> {{$headToUpperCase && $headToUpperCase}} has the best free online games selection and offers the most fun experience to play alone or with friends. We offer instant play to all our games without downloads, login, popups or other distractions. Our games are playable on desktop, tablet and mobile so you can enjoy them at home or on the road. Every month over 50 million gamers from all over the world play their favorite games on  {{$headToUpperCase && $headToUpperCase}}.</p>
        <h3>Our game selection</h3>
        <p>Experience new games every day on our platform! Play popular titles like
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[0] && allGames[0].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[0] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[0] && allGames[0].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[0] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[0])">{{allGames && allGames[0] && allGames[0].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[1] && allGames[1].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[1] && allGames[1].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[1] && allGames[1].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[1] && allGames[1].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[1])">{{allGames && allGames[1] && allGames[1].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[2] && allGames[2].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[2] && allGames[2].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[2] && allGames[2].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[2] && allGames[2].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[2])">{{allGames && allGames[2] && allGames[2].Name}}</a>
          and
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[3] && allGames[3].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[3] && allGames[3].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[3] && allGames[3].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[3] && allGames[3].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[3])">{{allGames && allGames[3] && allGames[3].Name}}</a>
          on {{$headToUpperCase && $headToUpperCase}}. Enjoy classics like
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[4] && allGames[4].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[4] && allGames[4].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[4] && allGames[4].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[4] && allGames[4].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[4])">{{allGames && allGames[4] && allGames[4].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[5] && allGames[5].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[5] && allGames[5].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[5] && allGames[5].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[5] && allGames[5].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[5])">{{allGames && allGames[5] && allGames[5].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[6] && allGames[6].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[6] && allGames[6].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[6] && allGames[6].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[6] && allGames[6].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[6])">{{allGames && allGames[6] && allGames[6].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[7] && allGames[7].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[7] && allGames[7].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[7] && allGames[7].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[7] && allGames[7].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[7])">{{allGames && allGames[7] && allGames[7].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[8] && allGames[8].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[8] && allGames[8].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[8] && allGames[8].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[8] && allGames[8].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[8])">{{allGames && allGames[8] && allGames[8].Name}}</a>,
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[9] && allGames[9].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[9] && allGames[9].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[9] && allGames[9].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[9] && allGames[9].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[9])">{{allGames && allGames[9] && allGames[9].Name}}</a>
          and
          <a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+(allGames && allGames[10] && allGames[10].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[10] && allGames[10].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+(allGames && allGames[10] && allGames[10].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[10] && allGames[10].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): ''))" @click="iconClick(allGames && allGames[10])">{{allGames && allGames[10] && allGames[10].Name}}</a>
          for free. With over 2000 games to choose from, the excitement never stops at {{$headToUpperCase && $headToUpperCase}}!
        </p>
        <h3>Start playing</h3>
        <p>Unsure what game to play? Start your game discovery on our homepage or pick a game from any of these popular categories:</p>
        <ul>
          <li v-for="(item,index) in typeList.slice(0,6)" :key="index">
            <a :href="'/M/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="classClick(item.type)">
              {{item.type.slice(0,1).toUpperCase() + item.type.slice(1).toLowerCase()}} Games
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MobileLogo from '@/components/MobileLogo.vue';
import BottomList from "@/components/MobileTerminal/MobileHome/BottomList";
import huore from '@/assets/huore.png';
import {
  clickGameLog,
  Observer,
  recentGame,
  pageOutLog,
  iconClickEscalation,
  getGameTypeList,
  clickClassificationLog,
  advMouseenterPublic
} from '@/utils/utils.js';
export default {
  name: "AppList",
  props: ['appGameList'],
  components: {
    BottomList,MobileLogo
  },
  data() {
    return {
      bigImg: [], // 大图片
      centreImg: [], // 中图片
      smallImg: [], // 小图片
      clientWidth: 0, // 屏幕宽度
      adv_box_2: 'adv_box_2', // 有广告样式
      adv_box: 'adv_box', // 无广告样式
      adv_box_4: 'adv_box_4', // 有广告样式
      adv_box_3: 'adv_box_3', // 无广告样式
      caPub: null,
      slot: null,
      slot2: null,
      mNoAfc: null,
      gptId: null,
      gptId2: null,
      noAdvertisements: null, // 1没广告
      huore,
      allGames: [], // 全部游戏
      typeList: [], // 游戏类型
      originCopyWriting: this.$originCopyWriting,
      newJumpUrl: null,
      jumpSubdomain: null,
      adv_style: null
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { m_home_slot, m_home_slot2, noAdvertisements, channel_ca_pub, mNoAfc, m_home_gpt, m_home_gpt2, jumpSubdomain, adv_style } = channelInfo || {}
    this.slot = m_home_slot
    this.slot2 = m_home_slot2
    this.caPub = channel_ca_pub
    this.noAdvertisements = noAdvertisements
    this.mNoAfc = mNoAfc
    let gpt = m_home_gpt && m_home_gpt.split(",") || []
    let gpt2 = m_home_gpt2 && m_home_gpt2.split(",") || []
    this.gptId = gpt[3]
    this.gptId2 = gpt2[3]
    this.jumpSubdomain = jumpSubdomain
    this.adv_style = adv_style
    // 跳转子域名详情页
    this.newJumpUrl = window.location.protocol + '//' + 'game01' + '.' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    // 获取需要曝光的item
    setTimeout(()=>{
      let itemArr = [...document.getElementsByClassName("sc-wr3rvk-0")]
      itemArr && Array.from(itemArr).map((item)=>{
        Observer('mobile_home').observe(item)
      })
    })
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { noAdvertisements, mNoAfc, m_home_gpt2 } = channelInfo || {}
    setTimeout(()=>{
      if (!noAdvertisements && !mNoAfc) {
        window.addAds()
      }
    },1300)
    if (mNoAfc) {
      console.log(window.isDisplay);
      if (window.isDisplay) {
        googletag.cmd.push(function() { googletag.pubads().refresh(); });
      }

      let leftAdv = document.createElement("script")
      leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(mHome[3]);};});"
      setTimeout(()=>{
        if (this.$originCopyWriting == 'qagame' || this.$originCopyWriting == 'getfun' || this.clientWidth > 550.9) {
          this.$refs.advRef.append(leftAdv)
        } else {
          this.$refs.advRef[0].append(leftAdv)
        }
      },600)
      if (m_home_gpt2) {
        let leftAdv2 = document.createElement("script")
        leftAdv2.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(mHome2[3]);window.isDisplay = true};});"
        console.log(this.$refs.advRef2);
        setTimeout(()=>{
          if (this.clientWidth > 550.9) {
            this.$refs.advRef2.append(leftAdv2)
          } else {
            this.$refs.advRef2[0].append(leftAdv2)
          }
        },900)
      }
    }
    window.onresize = () => {
      this.clientWidth = document.body.clientWidth
    }
    this.clientWidth = document.body.clientWidth
    this.typeList = getGameTypeList() || []
    this.getJson()
  },
  methods: {
    getJson() {
      let bigImg = []
      let centreImg = []
      let smallImg = []
      let arr = getAllJson().slice(0,161)
      arr && arr.map((item)=>{
        if (item.ImgSize == 1) {
          bigImg.push(item)
        } else if (item.ImgSize == 2) {
          centreImg.push(item)
        } else {
          smallImg.push(item)
        }
      })
      this.bigImg = bigImg
      this.centreImg = centreImg
      this.smallImg = smallImg
      this.allGames = arr
    },
    iconClick(item) {
      clickGameLog('mobile_home', item)
      recentGame(item)
      // 离开页面埋点
      pageOutLog('mobile_home')
      if (this.$originCopyWriting == 'ajoy') {
        bge && bge('event', 'button', {configId: '903985143796275456'});
        bge && bge('event', 'form_button', {configId: '903985143796275456'});
        sessionStorage.setItem('pixelType','1')
      }
      iconClickEscalation()
    },
    classClick(type) {
      clickClassificationLog('mobile_home',type)
    },
    advMouseenter() {
      advMouseenterPublic()
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
@media (hover: hover){
  .a_style_2:hover {
    transform: scale(1.01869) translate(0px, -4px)!important;
  }
  .a_style_2:hover::after {
    opacity: 1;
    background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
  }
  .a_style_2:hover .opacity {
    opacity: 1!important;
    transform: translate(0px, 0px)!important;
  }
}

@media (min-width: 111px) and (max-width: 550.9px) {
  .app_list_1, .bottom {
    width: 314px;
  }
  .adv_box {
    grid-template-areas:
          ". bigIp0 bigIp0"
          ". bigIp0 bigIp0"
          ". . ."
          "bigIp6 bigIp6 ."
          "bigIp6 bigIp6 ."
          ". . ."
  }
  .adv_box_6 {
    grid-template-areas:
          "logo bigIp0 bigIp0"
          ". bigIp0 bigIp0"
          ". . ."
          "bigIp6 bigIp6 ."
          "bigIp6 bigIp6 ."
          ". . ."
  }
  .adv_box_2 {
    grid-template-areas:
          ". bigIp0 bigIp0"
          ". bigIp0 bigIp0"
          ". . ."
          "adv adv adv"
          "adv adv adv"
          "adv adv adv"
          "bigIp6 bigIp6 ."
          "bigIp6 bigIp6 ."
          ". . ."
  }
}
@media (min-width: 551px) and (max-width: 660.9px) {
  .big_box, .bottom {
    width: 534px;
  }
  .adv_box_7 {
    --gridTemplateColumns: 5;
    grid-template-areas:
        "logo . ip3 ip3 ."
        "ip4 ip4 ip3 ip3 ."
        "ip4 ip4 . . ."
        "bigIp0 bigIp0 bigIp0 . ."
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        ". . ip6 ip6 ."
        ". . ip6 ip6 ."
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        ". . bigIp1 bigIp1 bigIp1"
        ". . ip8 ip8 ."
        "ip9 ip9 ip8 ip8 ."
        "ip9 ip9 . . ."
        "bigIp2 bigIp2 bigIp2 . ."
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        ". . ip11 ip11 ."
        ". . ip11 ip11 ."
        ". . . . ."
        ". . . . ."
        ". ip12 ip12 . ."
        ". ip12 ip12 . ."
        ". . . . ."
        ". . . . ."
        ". . . ip13 ip13"
        ". . . ip13 ip13"
        ". . . . ."
        "ip14 ip14 . . ."
        "ip14 ip14 . . ."
        ". . . . ."
        ". ip15 ip15 . ."
        ". ip15 ip15 . ."
        ". . . ip16 ip16"
        ". . . ip16 ip16"
  }
  .adv_box_4 {
    --gridTemplateColumns: 5;
    grid-template-areas:
        ". . ip3 ip3 ."
        "ip4 ip4 ip3 ip3 ."
        "ip4 ip4 . . ."
        "adv adv adv adv adv"
        "adv adv adv adv adv"
        "adv adv adv adv adv"
        "bigIp0 bigIp0 bigIp0 . ."
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        ". . ip6 ip6 ."
        ". . ip6 ip6 ."
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        ". . bigIp1 bigIp1 bigIp1"
        ". . ip8 ip8 ."
        "ip9 ip9 ip8 ip8 ."
        "ip9 ip9 . . ."
        "bigIp2 bigIp2 bigIp2 . ."
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        ". . ip11 ip11 ."
        ". . ip11 ip11 ."
        ". . . . ."
        ". . . . ."
        ". ip12 ip12 . ."
        ". ip12 ip12 . ."
        ". . . . ."
        ". . . . ."
        ". . . ip13 ip13"
        ". . . ip13 ip13"
        ". . . . ."
        "ip14 ip14 . . ."
        "ip14 ip14 . . ."
        ". . . . ."
        ". ip15 ip15 . ."
        ". ip15 ip15 . ."
        ". . . ip16 ip16"
        ". . . ip16 ip16"
  }
  .adv_box_3 {
    --gridTemplateColumns: 5;
    grid-template-areas:
        ". . ip3 ip3 ."
        "ip4 ip4 ip3 ip3 ."
        "ip4 ip4 . . ."
        "adv adv adv adv adv"
        "adv adv adv adv adv"
        "adv adv adv adv adv"
        "bigIp0 bigIp0 bigIp0 . ."
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        ". . ip6 ip6 ."
        ". . ip6 ip6 ."
        "adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2"
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        ". . bigIp1 bigIp1 bigIp1"
        ". . ip8 ip8 ."
        "ip9 ip9 ip8 ip8 ."
        "ip9 ip9 . . ."
        "bigIp2 bigIp2 bigIp2 . ."
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        ". . ip11 ip11 ."
        ". . ip11 ip11 ."
        ". . . . ."
        ". . . . ."
        ". ip12 ip12 . ."
        ". ip12 ip12 . ."
        ". . . . ."
        ". . . . ."
        ". . . ip13 ip13"
        ". . . ip13 ip13"
        ". . . . ."
        "ip14 ip14 . . ."
        "ip14 ip14 . . ."
        ". . . . ."
        ". ip15 ip15 . ."
        ". ip15 ip15 . ."
        ". . . ip16 ip16"
        ". . . ip16 ip16"
  }
  .adv_box_8 {
    --gridTemplateColumns: 5;
    grid-template-areas:
        "logo . ip3 ip3 ."
        "ip4 ip4 ip3 ip3 ."
        "ip4 ip4 . . ."
        "bigIp0 bigIp0 bigIp0 . ."
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        "bigIp0 bigIp0 bigIp0 ip5 ip5"
        ". . ip6 ip6 ."
        ". . ip6 ip6 ."
        "adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2"
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        "ip7 ip7 bigIp1 bigIp1 bigIp1"
        ". . bigIp1 bigIp1 bigIp1"
        ". . ip8 ip8 ."
        "ip9 ip9 ip8 ip8 ."
        "ip9 ip9 . . ."
        "bigIp2 bigIp2 bigIp2 . ."
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        "bigIp2 bigIp2 bigIp2 ip10 ip10"
        ". . ip11 ip11 ."
        ". . ip11 ip11 ."
        ". . . . ."
        ". . . . ."
        ". ip12 ip12 . ."
        ". ip12 ip12 . ."
        ". . . . ."
        ". . . . ."
        ". . . ip13 ip13"
        ". . . ip13 ip13"
        ". . . . ."
        "ip14 ip14 . . ."
        "ip14 ip14 . . ."
        ". . . . ."
        ". ip15 ip15 . ."
        ". ip15 ip15 . ."
        ". . . ip16 ip16"
        ". . . ip16 ip16"
  }
}
@media (min-width: 661px) and (max-width: 880.9px) {
  .big_box, .bottom {
    width: 644px;
    .adv_box_7 {
      --gridTemplateColumns: 6;
      grid-template-areas:
        "logo ip3 ip3 . ip4 ip4"
        ". ip3 ip3 . ip4 ip4"
        ". . . . . ."
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        ". . . bigIp0 bigIp0 bigIp0"
        ". ip6 ip6 . . ."
        ". ip6 ip6 . ip7 ip7"
        ". . . . ip7 ip7"
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 ip8 ip8 ."
        ". . . ip8 ip8 ."
        ". ip9 ip9 . . ."
        ". ip9 ip9 ip10 ip10 ."
        ". . . ip10 ip10 ."
        "ip11 ip11 . . . ."
        "ip11 ip11 . . . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 ip12 ip12"
        ". . . . ip12 ip12"
        ". ip13 ip13 . . ."
        ". ip13 ip13 . . ."
        ". . . . ip14 ip14"
        ". . . . ip14 ip14"
        ". ip15 ip15 . . ."
        ". ip15 ip15 . . ."
        ". . . ip16 ip16 ."
        ". . . ip16 ip16 ."
    }
    .adv_box_4 {
      --gridTemplateColumns: 6;
      grid-template-areas:
        ". ip3 ip3 . ip4 ip4"
        ". ip3 ip3 . ip4 ip4"
        ". . . . . ."
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        ". . . bigIp0 bigIp0 bigIp0"
        ". ip6 ip6 . . ."
        ". ip6 ip6 . ip7 ip7"
        ". . . . ip7 ip7"
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 ip8 ip8 ."
        ". . . ip8 ip8 ."
        ". ip9 ip9 . . ."
        ". ip9 ip9 ip10 ip10 ."
        ". . . ip10 ip10 ."
        "ip11 ip11 . . . ."
        "ip11 ip11 . . . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 ip12 ip12"
        ". . . . ip12 ip12"
        ". ip13 ip13 . . ."
        ". ip13 ip13 . . ."
        ". . . . ip14 ip14"
        ". . . . ip14 ip14"
        ". ip15 ip15 . . ."
        ". ip15 ip15 . . ."
        ". . . ip16 ip16 ."
        ". . . ip16 ip16 ."
    }
    .adv_box_3 {
      --gridTemplateColumns: 6;
      grid-template-areas:
        ". ip3 ip3 . ip4 ip4"
        ". ip3 ip3 . ip4 ip4"
        ". . . . . ."
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
        "adv adv adv adv adv adv"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        ". . . bigIp0 bigIp0 bigIp0"
        ". ip6 ip6 . . ."
        ". ip6 ip6 . ip7 ip7"
        ". . . . ip7 ip7"
        "adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2"
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 ip8 ip8 ."
        ". . . ip8 ip8 ."
        ". ip9 ip9 . . ."
        ". ip9 ip9 ip10 ip10 ."
        ". . . ip10 ip10 ."
        "ip11 ip11 . . . ."
        "ip11 ip11 . . . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 ip12 ip12"
        ". . . . ip12 ip12"
        ". ip13 ip13 . . ."
        ". ip13 ip13 . . ."
        ". . . . ip14 ip14"
        ". . . . ip14 ip14"
        ". ip15 ip15 . . ."
        ". ip15 ip15 . . ."
        ". . . ip16 ip16 ."
        ". . . ip16 ip16 ."
    }
    .adv_box_8 {
      --gridTemplateColumns: 6;
      grid-template-areas:
        "logo ip3 ip3 . ip4 ip4"
        ". ip3 ip3 . ip4 ip4"
        ". . . . . ."
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . bigIp0 bigIp0 bigIp0"
        ". . . bigIp0 bigIp0 bigIp0"
        ". ip6 ip6 . . ."
        ". ip6 ip6 . ip7 ip7"
        ". . . . ip7 ip7"
        "adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2"
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 . . ."
        "bigIp1 bigIp1 bigIp1 ip8 ip8 ."
        ". . . ip8 ip8 ."
        ". ip9 ip9 . . ."
        ". ip9 ip9 ip10 ip10 ."
        ". . . ip10 ip10 ."
        "ip11 ip11 . . . ."
        "ip11 ip11 . . . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 . ."
        ". bigIp2 bigIp2 bigIp2 ip12 ip12"
        ". . . . ip12 ip12"
        ". ip13 ip13 . . ."
        ". ip13 ip13 . . ."
        ". . . . ip14 ip14"
        ". . . . ip14 ip14"
        ". ip15 ip15 . . ."
        ". ip15 ip15 . . ."
        ". . . ip16 ip16 ."
        ". . . ip16 ip16 ."
    }
  }
}
@media (min-width: 881px) {
  .big_box, .bottom {
    width: 864px;
    .adv_box_7 {
      --gridTemplateColumns: 8;
      grid-template-areas:
        "logo ip3 ip3 . . . . ."
        ". ip3 ip3 . . . ip4 ip4"
        ". . . . . . ip4 ip4"
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
        "ip5 ip5 . . . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . ip6 ip6 bigIp0 bigIp0 bigIp0"
        ". . . ip6 ip6 bigIp0 bigIp0 bigIp0"
        ". ip7 ip7 . . . . ."
        ". ip7 ip7 . . . ip8 ip8"
        ". . . . . . ip8 ip8"
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 ip10 ip10 . . ."
        ". . . ip10 ip10 . ip11 ip11"
        ". ip12 ip12 . . . ip11 ip11"
        ". ip12 ip12 ip13 ip13 . . ."
        ". . . ip13 ip13 . . ."
        "ip14 ip14 . . . . . ."
        "ip14 ip14 . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . ip15 ip15 . . . ."
        ". . ip15 ip15 ip16 ip16 . ."
        ". . . . ip16 ip16 . ."
    }
    .adv_box_4 {
      --gridTemplateColumns: 8;
      grid-template-areas:
        ". ip3 ip3 . . . . ."
        ". ip3 ip3 . . . ip4 ip4"
        ". . . . . . ip4 ip4"
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
        "ip5 ip5 . . . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . ip6 ip6 bigIp0 bigIp0 bigIp0"
        ". . . ip6 ip6 bigIp0 bigIp0 bigIp0"
        ". ip7 ip7 . . . . ."
        ". ip7 ip7 . . . ip8 ip8"
        ". . . . . . ip8 ip8"
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 ip10 ip10 . . ."
        ". . . ip10 ip10 . ip11 ip11"
        ". ip12 ip12 . . . ip11 ip11"
        ". ip12 ip12 ip13 ip13 . . ."
        ". . . ip13 ip13 . . ."
        "ip14 ip14 . . . . . ."
        "ip14 ip14 . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . ip15 ip15 . . . ."
        ". . ip15 ip15 ip16 ip16 . ."
        ". . . . ip16 ip16 . ."
    }
    .adv_box_3 {
      --gridTemplateColumns: 8;
      grid-template-areas:
        ". ip3 ip3 . . . . ."
        ". ip3 ip3 . . . ip4 ip4"
        ". . . . . . ip4 ip4"
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
        "adv adv adv adv adv adv adv adv"
        "ip5 ip5 . . . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . ip6 ip6 bigIp0 bigIp0 bigIp0"
        ". . . ip6 ip6 bigIp0 bigIp0 bigIp0"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv2"
        ". ip7 ip7 . . . . ."
        ". ip7 ip7 . . . ip8 ip8"
        ". . . . . . ip8 ip8"
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 ip10 ip10 . . ."
        ". . . ip10 ip10 . ip11 ip11"
        ". ip12 ip12 . . . ip11 ip11"
        ". ip12 ip12 ip13 ip13 . . ."
        ". . . ip13 ip13 . . ."
        "ip14 ip14 . . . . . ."
        "ip14 ip14 . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . ip15 ip15 . . . ."
        ". . ip15 ip15 ip16 ip16 . ."
        ". . . . ip16 ip16 . ."

    }
    .adv_box_8 {
      --gridTemplateColumns: 8;
      grid-template-areas:
        "logo ip3 ip3 . . . . ."
        ". ip3 ip3 . . . ip4 ip4"
        ". . . . . . ip4 ip4"
        "ip5 ip5 . . . bigIp0 bigIp0 bigIp0"
        "ip5 ip5 . ip6 ip6 bigIp0 bigIp0 bigIp0"
        ". . . ip6 ip6 bigIp0 bigIp0 bigIp0"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv2"
        "adv2 adv2 adv2 adv2 adv2 adv2 adv2 adv2"
        ". ip7 ip7 . . . . ."
        ". ip7 ip7 . . . ip8 ip8"
        ". . . . . . ip8 ip8"
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 . . ip9 ip9 ."
        "bigIp1 bigIp1 bigIp1 ip10 ip10 . . ."
        ". . . ip10 ip10 . ip11 ip11"
        ". ip12 ip12 . . . ip11 ip11"
        ". ip12 ip12 ip13 ip13 . . ."
        ". . . ip13 ip13 . . ."
        "ip14 ip14 . . . . . ."
        "ip14 ip14 . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . . bigIp2 bigIp2 bigIp2 . ."
        ". . ip15 ip15 . . . ."
        ". . ip15 ip15 ip16 ip16 . ."
        ". . . . ip16 ip16 . ."

    }
  }
}
.app_list_1 {
  margin: 0px auto;
  flex-grow: 1;
}
.app_list_1:nth-of-type(1){
  .adv_box::before{
    content: "";
    display: block;
  }
  .adv_box_2::before{
    content: "";
    display: block;
  }
  //.adv_box_6::before{
  //  content: "";
  //  display: block;
  //}
}
.adv_box, .adv_box_2, .adv_box_6 {
  display: grid;
  grid-template-rows: repeat(auto-fill, 94px);
  grid-gap: 16px;
  grid-auto-flow: dense;
  justify-content: center;
  margin: 16px auto 0px;
  padding: 0px;
  list-style-type: none;
  --gridTemplateColumns: 3;
  grid-template-columns: repeat(var(--gridTemplateColumns),94px);
}
.img_style {
  display: block;
  --minSize: 94px;
  min-width: var(--minSize);
  min-height: var(--minSize);
  border-radius: inherit;
  aspect-ratio: 1 / 1;
}
.a_style_2 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
  user-select: none;
  aspect-ratio: 1 / 1;
}
.a_style_1 {
  display: block;
  transition: transform .6s cubic-bezier(.25, .1, .25, 1);
  border-radius: 16px;
  position: relative;
}
.a_style_1:hover {
  transform: scale(1.04255) translate(0px, -4px);
  transition-duration: 0.3s;
}
.a_style_1::after {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
  z-index: 4;
  transition: box-shadow .6s cubic-bezier(.25, .1, .25, 1),opacity .3s cubic-bezier(.25, .1, .25, 1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
  border-radius: 16px;
  contain: strict;
}
.position {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: 6px;
  color: rgb(255, 255, 255);
  font: 700 var(--tileFontSize,12px)/1.3 'Proxima Nova',sans-serif;
  text-align: center;
  z-index: 6;
  transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s,opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
  transform: translate(0px, 8px);
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
  word-break: break-all;
}
.hot_box {
  position: absolute;
  z-index: 5;
  left: -6px;
  right: 0px;
  height: 28px;
  top: 8px;
  font: 700 11px/28px Proxima Nova, Open Sans, Gill Sans MT, Gill Sans, Arial, sans-serif;
  color: #009cff;
  text-transform: uppercase;
  padding: 0px 8px 0px 6px;
  pointer-events: none;
  .hot_img {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    background: rgb(236, 27, 37);
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
    border-radius: 3px 14px 14px 0px;
    //padding: 0px 8px 0px 3px;
    img{
      width: 39px;
      height: 39px;
      //padding: 5px 0px 0px 3px;
      z-index: 2;
      box-sizing: border-box;
    }
  }
}
.hot_box:before {
  background: #fff;
  bottom: -4px;
  height: 4px;
  width: 4px;
  z-index: 0;
  content: "";
  left: 0;
  position: absolute;
}
.hot_box:after {
  background: #bac9de;
  border-radius: 4px 0 0 4px;
  bottom: -8px;
  height: 8px;
  width: 6px;
  z-index: 1;
  content: "";
  left: 0;
  position: absolute;
}
.big_box {
  margin: 0px auto;
  flex-grow: 1;
  .adv_box_3, .adv_box_4, .adv_box_7, .adv_box_8 {
    display: grid;
    grid-template-rows: repeat(auto-fill, 94px);
    grid-gap: 16px;
    grid-auto-flow: dense;
    justify-content: center;
    margin: 16px auto 0px;
    padding: 0px;
    list-style-type: none;
    //--gridTemplateColumns: 3;
    grid-template-columns: repeat(var(--gridTemplateColumns),94px);
    .ul_style {
      display: contents;
      li{
        display: contents;
        a {
          text-decoration: none;
          color: #009cff;
        }
      }
    }
  }
  .adv_box_3::before {
    content: "";
    display: block;
  }
  .adv_box_4::before {
    content: "";
    display: block;
  }
}
.bottom{
  margin: 0 auto;
  .bottom-text {
    position: relative;
    margin: 30px 0 0;
    padding: 18px 24px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    h1{
      font: 500 24px Torus,sans-serif;
    }
    p{
      margin: 24px 0;
      font: 400 16px/24px Proxima Nova,Open Sans,Gill Sans MT,Gill Sans,Arial,sans-serif;
      color: #002b50;
    }
    a{
      color: #054a91;
      text-decoration: underline;
    }
    h3{
      color: #002b50;
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    ul{
      padding-left: 40px;
      li{
        margin-bottom: 5px;
        font-size: 16px;
      }
    }
    .about{
      color: #5d6b84;
      font-size: 9px;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 3px 0 0;
      text-transform: uppercase;
    }
    .title{
      font-size: 20px;
      color: #002b50;
    }
  }
}
</style>
